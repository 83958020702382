import React from 'react'
import NavigationBar from './header/NewNav'

const Header = () => {
  return (
    <header className={`shadow-4xl lg:shadow-none sticky top-0 z-60`}>
      <NavigationBar/>
    </header>
  )
}

export default Header
